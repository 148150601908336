import * as React from "react"
import Layout from "../../components/Layout"
import { Row, Col } from "react-bootstrap"
import Section from "../../components/Section"
import { Link } from "gatsby"
import BadgeDownload from "../../components/BadgeDownload"


const IndexPage = () => {
    const root = "/img/download/badges"


    return (
        <Layout pageTitle="Member Badges">
            <Row>
                <Col>
                    <Link to="/members">Back</Link>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={8}>
                    <p className="para">
                        Use these badges to announce your membership on social media. 
                        You can also add these to your email and website footers.
                        Select 'transparent background' when downloading to remove the white backgroud if needed to put over and image or coloured background.
                    </p>
                   
                    
                </Col>
            </Row>
            <BadgeDownload
                root={root}
                name="Member Badge"
                filename="member-badge"
                text="Use this to shout about your membership in either My Success Story or My Boardroom! "
                squareHref="https://www.canva.com/design/DAFqfrJu9XI/BUb8vHPeljNwPV38VoJa_g/view?utm_content=DAFqfrJu9XI&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                bookHref=""
           
           />
           
        </Layout>
    )
}




export default IndexPage
